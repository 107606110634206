import { Injectable } from "@angular/core";
import { AppSettings } from "../shared/appsettings";
import { EligibilityService } from "./eligibility.service";
import { AuthIntakeForm } from "../models/authForm/authIntakeForm";
import { LookupValue } from "../models/Lookups/lookupValue";
import { CLOSED_OUTCOME, MISC_PROVIDERS, REQUESTING_PROVIDER, VOID_OUTCOME, WITHDRAWN_OUTCOME } from "../../assets/constants";
import { MemberService } from "@CommonLib/services/member-service.service";
import { MemberEligibility } from "@CommonLib/models/member/memberEligibility";
import * as moment from "moment";


@Injectable({
  providedIn: 'root'
})

export class EligibilityHelperService {
  constructor(
    private memberService: MemberService, private eligibilityService: EligibilityService,
    private appSettings: AppSettings) {
    if (this.appSettings.features) {
      if (this.appSettings.features['realTimeEligibility']) {
        this.realTimeEligibilityFlag = JSON.parse(this.appSettings.features['realTimeEligibility']);
      }
    }
  }

  realTimeEligibilityFlag: boolean = false;

  getEligibility(data: AuthIntakeForm, lookupData: LookupValue[], eligiList: MemberEligibility[], isConcurrentAuth: boolean):void {
    const reqProviderId = lookupData.filter(d => d.lookupStandardValue.toLowerCase() == REQUESTING_PROVIDER.toLowerCase()).map(o => o.lookupValueID)[0];
    const today = moment().format("yyyy-MM-DD");
    
    // var y = eligiList.some(e => e.freshnessDate !== today);
    const isFreshEligList =  !eligiList.some(e => e.freshnessDate !== today);
    var providerNPI: string | null;
    const tempProvider = data.temporaryProviderAddress!.filter(a => a.providerTypeId == reqProviderId)[0];
    if (tempProvider) {
      providerNPI = tempProvider.providerNPI;
    }
    else {
      providerNPI = data.authIntakeHeader.reqNPI!;
    }

    if (MISC_PROVIDERS.includes(providerNPI)) {
      providerNPI = null;
    }

    if (!providerNPI) {
      console.log("No valid Requesting provider selected, cannot retrieve Availity eligibility data.");
      return;
    }
    if (isFreshEligList) {
      //eligibility data is fresh no need to call
      return;
    }
    if (this.isGetRTE(data, lookupData, isConcurrentAuth)) {
        this.eligibilityService.getMemberEligibility(data.authIntakeHeader.mvdId, data.authIntakeHeader.authIntakeId, 
            data.authIntakeHeader.reqNPI!).subscribe();
    }
    else {
      this.memberService.getMemberEligibility(data.authIntakeHeader.mvdId, data.authIntakeHeader.authIntakeId, 
        this.appSettings.memberServiceBaseUrl, this.appSettings.customerId, this.appSettings.productId
      ).subscribe();
    }  
  }

  isGetRTE(data: AuthIntakeForm, lookupData: LookupValue[], isConcurrentSub: boolean) {
    const withdrawnOutcomeIds = lookupData.filter(d => d.lookupStandardValue.toLowerCase() == WITHDRAWN_OUTCOME.toLowerCase()).map(o => o.lookupValueID);
    const closedOutcomeIds = lookupData.filter(d => d.lookupStandardValue.toLowerCase() == CLOSED_OUTCOME.toLowerCase()).map(o => o.lookupValueID);
    const voidedOutcomeIds = lookupData.filter(d => d.lookupStandardValue.toLowerCase() == VOID_OUTCOME.toLowerCase()).map(o => o.lookupValueID);
    const outcomeIds = [...withdrawnOutcomeIds, ...closedOutcomeIds, ...voidedOutcomeIds];
    const authStatusId = data.authIntakeDetail[0].currentStatus;
    const authDetailNumber= data.authIntakeHeader.displayAuthNumber?.split('-')[0];
    const isConcurrentAuthNumber = authDetailNumber !== undefined && authDetailNumber != 'N/A' ? authDetailNumber !== '0001': null;

    const isConcurrent = !isConcurrentAuthNumber ? isConcurrentSub : isConcurrentAuthNumber;

    if (this.realTimeEligibilityFlag && !outcomeIds.some(id => authStatusId !== undefined && id == authStatusId) && !isConcurrent) {
      return true;
    }
    else return false;
  }
}
