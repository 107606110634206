import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SigninRedirectCallbackComponent } from './services/signin-redirect-callback';
import { SignoutRedirectCallbackComponent } from './services/signout-redirect-callback';
import { AuthGuardService } from './shared/guards/auth-guard.service';
import { CanDeactivateGuard } from './shared/guards/can-deactivate-guard.service';
import { CustomPreloadingStrategyService } from './services/custom-preloading-strategy.service';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'member',
    loadChildren: () => import('./member/member.module').then(m => m.MemberModule),
    canActivate: [AuthGuardService],
    data: { preload: true }
  },
  {
    path: 'member/:id',
    loadChildren: () => import('./member/member.module').then(m => m.MemberModule),
    canActivate: [AuthGuardService],
    data: { preload: true }
  },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  {
    path: 'supervisor-tools',
    loadChildren: () => import('./supervisor/supervisor.module').then(m => m.SupervisorModule),
  },
  {
    path: 'configurations',
    loadChildren: () => import('./configurations/configurations.module').then(m => m.ConfigurationsModule),
    canActivate: [AuthGuardService],
    data: { preload: true }
  },
  {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService, onSameUrlNavigation: 'reload' })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: []
})
export class AppRoutingModule { }
