export class LookupValueConfig {
  id!: number;
  lookupSetExtensionId!: number;
  customerId!: number;
  productId!: number;
  entityId!: number;
  entityName!: string;
  entityParentId!: number;
  entityTopMostParentId!: number;
  entityHierarchyId!: number;
  hierarchyLevel!: number;
  entityHierarchyName!: string;
  lookupSetGUID!: string;
  lookupValueGUID!: string;
  extensionData!: LookupValueExtensionData | null;
  lookupValueExtention!: LookupValueExtensionData | null;
  startDate!: string | null;
  endDate!: string | null;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  isActive!: boolean;
}

export class LookupValueConfigVM {
  id!: number;
  lookupSetExtensionId!: number;
  customerId!: number;
  productId!: number;
  entityId!: number;
  entityName!: string;
  entityParentId!: number;
  entityTopMostParentId!: number;
  entityHierarchyId!: number;
  hierarchyLevel!: number;
  entityHierarchyName!: string;
  lookupSetGUID!: string;
  lookupValueGUID!: string;
  extensionData!: string | null;
  lookupValueExtention!: TemplateExtensions[];
  startDate!: string | null;
  endDate!: string | null;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  isActive!: true
}
export class TemplateExtensions {
  template!: string;
  templateUrl!: string | null;
  workflow!: string
}

export class LookupValueExtensionData {
  templateExtensions: TemplateExtensions[]=[];
}