<div class="search-member-n-auth-container" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <form [formGroup]="searchForm">
    <div data-layout="row wrap" data-layout-align="start center" data-layout-gap="1em" class="mb-15">
      <div class="radio-group-label" id="search-auth-by">Search by</div>
      <mat-radio-group aria-labelledby="search-auth-by">
        <mat-radio-button value="Auth Number" [checked]="true" (change)="radioChange($event)">Auth #</mat-radio-button>
        <mat-radio-button value="Member ID" (change)="radioChange($event)">Member ID</mat-radio-button>
        <mat-radio-button value="Name and DOB" (change)="radioChange($event)">Name / DOB</mat-radio-button>
      </mat-radio-group>
    </div>
    <div data-layout="row wrap" data-layout-align="space-between center" class="mb-5">
      <div [hidden]="!searchByAuthNumber">
        <mat-form-field appearance="fill">
          <mat-label>Authorization #</mat-label>
          <input matInput placeholder="" [(ngModel)]="authNumber" (keydown.enter)="search()" (keypress)="keyPressNumeric($event)" [disabled]="!searchByAuthNumber" [ngModelOptions]="{standalone: true}" cdkFocusInitial>
        </mat-form-field>
      </div>
      <div [hidden]="!searchByMemberId">
        <mat-form-field appearance="fill">
          <mat-label>Member ID</mat-label>
          <input matInput placeholder="" [(ngModel)]="memberId" [disabled]="!searchByMemberId" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
      <div data-layout="row wrap" data-layout-gap="1em" [hidden]="!searchByNameDob">
        <mat-form-field appearance="fill" style="width: 12em">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="" [(ngModel)]="firstName" (keypress)="keyPressAlphaNumeric($event)" [disabled]="!searchByNameDob" formControlName="fName">
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 12em">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="" [(ngModel)]="lastName" (keypress)="keyPressAlphaNumeric($event)" [disabled]="!searchByNameDob" formControlName="lName">
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 135px">
          <mat-label>DOB</mat-label>
          <input matInput [matDatepicker]="dobPicker" [disabled]="!searchByNameDob" [(ngModel)]="dob" formControlName="dobSearch">
          <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
          <mat-datepicker #dobPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <button mat-raised-button color="vdt-primary" (click)="search()" [disabled]="!formValid()" class="compact with-icon">Search Member<mat-icon class="material-icons-outlined icon suffix">search</mat-icon></button>
    </div>
  </form>
  <p *ngIf="noResults">{{notFoundMsg}}</p>
  <div *ngIf="!noResults" class="search-auth-results content-scrollable">
    <div *ngFor="let item of item">
        <!-- Inject Member Search Results HTML Block Template -->
        <ng-container [ngTemplateOutlet]="memberSearchTemplate" [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
    </div>
  </div>
</div>

<!-- Member Search Results HTML Block Template -->
<ng-template #memberSearchTemplate let-item="item">
  <div data-flex="1 0 auto" class="data-list" [ngClass]="{'mb-15': item['auth']?.length}">

    <!-- Member result with a radio button -->
    <mat-radio-group class="list-layout" name="memberAuthList" *ngIf="isMemberSelectable">
      <div class="card minimal affix-tr clickable">
        <mat-radio-button class="radio-button" [value]="item.mvdid" (change)="memberSelected($event)" *ngIf="isMemberSelectable"></mat-radio-button>
        <!-- Inject Member Data HTML Block Template -->
        <ng-container [ngTemplateOutlet]="memberDataTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
      </div>
    </mat-radio-group>

    <!-- Member result without a radio button -->
    <!-- Inject Member Data HTML Block Template -->
    <div class="card minimal" *ngIf="!isMemberSelectable">
      <ng-container [ngTemplateOutlet]="memberDataTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </div>

    <div *ngIf="!isAuthHidden">
      <hr />
      <div class="alert alert-info mt-15" role="alert">
        <span>{{item['auth']?.length}} existing authorizations found.</span>
      </div>

      <div *ngIf="item['auth']?.length">

        <!-- Auth result with a radio button -->
        <mat-radio-group class="list-layout" *ngIf="isAuthSelectable" (change)="authSelected($event)" name="memberAuthList">
          <div class="card minimal affix-tr clickable" *ngFor="let subItem of item['auth']">
            <mat-radio-button class="radio-button" [value]="subItem"
                              [disabled]="subItem.sensitiveData && !canViewSensitiveData">
            </mat-radio-button>
            <div class="card-content">
              <div data-flex="1 0 auto" class="data-list">
                <!-- Inject Auth List HTML Block Template -->
                <ng-container [ngTemplateOutlet]="authDataTemplate" [ngTemplateOutletContext]="{ subItem: subItem }">
                </ng-container>
              </div>
            </div>
          </div>
        </mat-radio-group>

        <!-- Auth result with an action button -->
        <div class="list-layout" *ngIf="!isAuthSelectable">
          <div class="card minimal" *ngFor="let subItem of item['auth']">
            <div class="card-content">
              <div data-flex="1 0 auto" class="data-list">
                <!-- Inject Auth List HTML Block Template -->
                <ng-container [ngTemplateOutlet]="authDataTemplate" [ngTemplateOutletContext]="{ subItem: subItem }">
                </ng-container>
              </div>
              <div class="button-br">
                <button mat-stroked-button color="vdt-primary" (click)="emitClickAction2(subItem)" *ngIf="!subItem.sensitiveData || canViewSensitiveData">View</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Member Data HTML Block Template -->
<ng-template #memberDataTemplate let-item="item">
  <div class="card-content">
    <ul class="full-width">
      <li data-layout="row" data-layout-align="space-between center" [ngClass]="{'mb-15': isAuthSelectable}">
        <span class="label cdk-visually-hidden">Member Name: </span>
        <span class="value"><span class="data-header">{{ item.memberName }}</span></span>
        <button *ngIf="!isAuthSelectable" mat-stroked-button color="vdt-primary" (click)="emitClickAction1(item)">
          {{ item['auth']?.length? 'View all auths for this member': 'Create a new auth for this member' }}
        </button>
      </li>
      <li>
        <span class="label">Member ID: </span>
        <span class="value">{{ item.memberId }}</span>
      </li>
      <li>
        <span class="label">Date of Birth: </span>
        <span class="value">{{ item.dob | date: DateFormat }}</span>
      </li>
      <li>
        <span class="label">Address: </span>
        <span class="value">{{ item.address1 }}, <span *ngIf="item.address2">{{ item.address2 }}, </span>{{
          item.city }}, {{ item.state }} {{ item.zip }}</span>
      </li>
      <li>
        <span class="label">LOB: </span>
        <span class="value">{{ item.lobName }}</span>
      </li>
    </ul>
  </div>
</ng-template>

<!-- Auth Data HTML Block Template -->
<ng-template #authDataTemplate let-subItem="subItem">
  <ul class="full-width">
    <li>
      <span class="label">Authorization #: </span>
      <span class="value">{{ subItem.authNumber }}</span>
    </li>
    <li>
      <span class="label">Authorization Status: </span>
      <span class="value">{{ subItem.status }}</span>
    </li>
    <li>
      <span class="label">Authorization Status Reason: </span>
      <span class="value">{{ subItem.statusReason }}</span>
    </li>
    <li>
      <span class="label">Authorization Type: </span>
      <span class="value">{{ subItem.authType }}</span>
    </li>
    <li>
      <span class="label">Dates of Service: </span>
      <span class="value">{{ subItem.authRequestDate | date: DateFormatSeconds }}</span>
    </li>    
    <li>
      <span class="label">Authorization Created: </span>
      <span class="value">{{ subItem.createdDate | date: DateFormatSeconds : timezone }}</span>
    </li>
    <li>
      <span class="label">Provider: </span>
      <span class="value">{{ subItem.reqProvider }}</span>
    </li>
    <li>
      <span class="label">Plan Id: </span>
      <span class="value">{{ subItem.planIdentifier }}</span>
    </li>
    <li class="truncate">
      <span class="label">Diagnosis: </span>
      <span class="value" *ngIf="!subItem.sensitiveData || canViewSensitiveData">{{ subItem.diagnosis }}</span>
    </li>
    <li class="truncate">
      <span class="label">Procedure: </span>
      <span *ngIf="!subItem.sensitiveData || canViewSensitiveData">
        <span class="value" *ngFor="let tip of subItem.procs; let i=index;" matTooltipClass="multiline-tooltip"
              [matTooltip]="tip.procDesc">{{ tip==''?'N/A':tip.procCodeId }}{{i != subItem.procs.length-1?',
          ':''}}</span>
      </span>
    </li>
  </ul>
</ng-template>