import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api/apiResponse";
import { AppSettings } from "../shared/appsettings";
import { AlertService } from "../_alert";
import { User } from "../models/user";
import { TaskTemplate } from "../models/task/tasktemplate";
import { AccessList } from "../models/rbac/accessList";
import { AccessControl } from "../models/rbac/accessControl";
import { UserDefinedField } from "../models/dynamicForm/userDefinedField";
import { UIElementAttributes } from "../models/configuration/uIelementAttributes";
import { LookupValueConfig } from "../models/configuration/lookupValueConfig";
import { LetterConfig } from "../models/configuration/letterConfig";
import { TokenStorageService } from "./token-storage.service";
import { Queue } from "../models/configuration/queue";
import { InterqualSettings } from "../models/interqual/interqualSettings";
import { UserPreferenceAttributes } from "../models/configuration/userPreferenceAttributes";
import { Manager } from "../models/configuration/manager";
import { APPCLASS_NOTE, ACCESS_MASK_CREATE, ACCESS_MASK_DELETE, ACCESS_MASK_EDIT, ACCESS_MASK_READ, ACCESS_MASK_DOWNLOAD } from "../../assets/constants";
import { UserConfiguration } from "../models/configuration/userConfiguration";
import { AppSettingsConfig } from "../models/configuration/appSettingsConfig";
import { AuthorizationRole } from "../models/configuration/authorizationRole";
import { RolePermissions } from "../models/configuration/rolePermissions";
import { AppClass } from "../models/configuration/appClass";
import { CustomerEntity } from "../models/configuration/customerEntity";
import { EntityHierarchyType } from "../models/configuration/entityHierarchyType";
import { NoteType } from "../models/configuration/noteType";
import { EventService } from "@CommonLib/services/events.service";

export interface UserNameData
{
    userNames: string;
    productId: number;
    customerId: number;
}

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  private configServiceBaseUrl!: string;
  private userListSubj = new BehaviorSubject<User[]>([]);
  userList = this.userListSubj.asObservable();  
  private groupListSubj = new BehaviorSubject<Queue[]>([]);
  groupList = this.groupListSubj.asObservable();   
  private userGroupListSubj = new BehaviorSubject<Queue[]>([]);
  userGroupList = this.userGroupListSubj.asObservable();    
  private managerListSubj = new BehaviorSubject<Manager[]>([]);
  managerList = this.managerListSubj.asObservable(); 
  private userListByIdSubj = new BehaviorSubject<User[]>([]);
  userListById = this.userListByIdSubj.asObservable();    
  private accessListSubj = new BehaviorSubject<AccessList[]>([]);
  private taskTemplateSubj = new BehaviorSubject<TaskTemplate[]>([]);
  taskTemplateList = this.taskTemplateSubj.asObservable();
  accessList = this.accessListSubj.asObservable();  
  queues!: Queue[];
  public acl!: AccessControl;
  public userDefinedFields = new BehaviorSubject<UserDefinedField[]>([]);
  public uiElementAttributes = new BehaviorSubject<UIElementAttributes[]>([]);
  public lookupValueConfig = new BehaviorSubject<LookupValueConfig[]>([]);
  public letterConfig = new BehaviorSubject<LetterConfig[]>([]);
  private userHierachySubj = new BehaviorSubject<string>('');
  userHierarchyObs = this.userHierachySubj.asObservable()
  public AuthType = new BehaviorSubject<string>('');
  public faxQueues = new BehaviorSubject<Queue[]>([]);
  faxQueuesListObs = this.faxQueues.asObservable();
  public userFaxQueues = new BehaviorSubject<Queue[]>([]);
  public interqualSettings = new BehaviorSubject<InterqualSettings>(new InterqualSettings);
  private environment = '';
  public UserPreferences = new BehaviorSubject<UserPreferenceAttributes[]>([]);
  public isSupervisor = false;
  public NoteTypeLookupValueConfig: LookupValueConfig[] = [];
  public NoteTypeCache: NoteType[] | undefined = [];

  constructor(private http: HttpClient, private appConfig: AppSettings, private tokenService: TokenStorageService, private eventService: EventService) {
    this.configServiceBaseUrl = this.appConfig.configServiceBaseUrl;
    this.environment = this.appConfig.environment;
  }

  getUserPreferences(userName: string | undefined): void {
    if (!userName) {
      return;
    }
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let userConfigUrl = this.configServiceBaseUrl + "UserAttributeConfig/UserPreference";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", userName);
    queryParams = queryParams.append("productId", this.appConfig.productId);

    this.http.get<ApiResponse<UserPreferenceAttributes[]>>(userConfigUrl, { headers: headers, params: queryParams }).subscribe(
        data => {
            if (data.succeeded == true) {
                this.UserPreferences.next(data.data!);         
            }
        }
    );
  }

  updateUserPreferences(vdtAttributeName: string, attributeType: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    const user = this.tokenService.getUser();
    let userConfigUrl = this.configServiceBaseUrl + "UserAttributeConfig/UpdateUserPreference";

    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", user.profile.preferred_username);
    queryParams = queryParams.append("productId", this.appConfig.productId);
    queryParams = queryParams.append("vdtAttributeName", vdtAttributeName);
    queryParams = queryParams.append("attributeType", attributeType);

    this.http.post<ApiResponse<number>>(userConfigUrl, null,  { headers: headers, params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          this.getUserPreferences(user.profile.preferred_username);
        }
      }
    );
  }

  getActiveUsers(): void {
    let userListUrl = this.configServiceBaseUrl + "User/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId;
    this.http.get<ApiResponse<any>>(userListUrl).subscribe(
        data => {
            if (data.succeeded == true) {
                this.userListSubj.next(data.data!);         
            }
        }
    );
  }  
  
  getAllQueues(): void {
      let groupListUrl = this.configServiceBaseUrl + "User/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId + "/Queue";
      this.http.get<ApiResponse<Queue[]>>(groupListUrl).subscribe(
          data => {
          if (data.succeeded == true && data.data) {
            const active = data.data.filter(q => q.isActive == true);
                  this.groupListSubj.next(active);  
              }
              return data;
          }
      );
  }

  getAllFaxQueues(): void {
    let groupListUrl = this.configServiceBaseUrl + "User/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId
      + "/FaxQueues";
    this.http.get<ApiResponse<Queue[]>>(groupListUrl)
      .subscribe(
        data => {
          if (data.succeeded == true && data.data) {
            const active = data.data.filter(q => q.isActive);
            this.faxQueues.next(active);
          }
          return data;
        }
      );
  }

  getUserGroups(userNames:string | undefined, isSupervisor = false): Observable<ApiResponse<Queue[]>> | undefined {
    this.isSupervisor = isSupervisor;
    if (userNames != undefined) {
      let groupListUrl = this.configServiceBaseUrl + "User/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId
      + "/User/" + userNames + "/Queues/";
      return this.http.get<ApiResponse<Queue[]>>(groupListUrl).pipe(
        map((response: ApiResponse<Queue[]>)=>{
          this.userGroupListSubj.next(response.data!);
          return response;
        })
      );
    }
    return;
  }

  async getManagers() {
    let managerListUrl = this.configServiceBaseUrl + "User/Product/" + this.appConfig.productId + "/Managers/";
    this.http.get<ApiResponse<Manager[]>>(managerListUrl)
    .subscribe(
        data => {
            if (data.succeeded == true) {
                this.managerListSubj.next(data.data!);  
            }
            return data;
        }
    );
  }

  async getUserFaxGroups(userName: string | undefined): Promise<any> {
    if (userName != undefined) {
      let groupListUrl = this.configServiceBaseUrl + "User/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId
        + "/User/" + userName + "/Queues?attributeTypeId=7";
      this.http.get<ApiResponse<Queue[]>>(groupListUrl)
        .subscribe(
          data => {
            if (data.succeeded == true) {
              this.userFaxQueues.next(data.data!);
            }
            return data;
          }
        );
    }
  }

  async getActiveUsersByUserName(userNames: string[]): Promise<void> {
    if (userNames.length === 0) return;
    const userNamesStr = Array.from(new Set(userNames.filter(n => n!=null)))?.join(',');
    const userNamesObj:UserNameData = {userNames: userNamesStr, productId:  this.appConfig.productId, customerId: this.appConfig.customerId};
    let userListUrl = this.configServiceBaseUrl + "User/UserByUserName"
    this.http.post<ApiResponse<User[]>>(userListUrl, userNamesObj).subscribe(
        data => {
            if (data.succeeded == true) {
                this.userListByIdSubj.next(data.data!);         
            }
        }
    );
  }

  getUserAccessList(userName: string) {
    if (userName == undefined || userName.length == 0) return;
    let accessListUrl = this.configServiceBaseUrl + "Access/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId
      + "/User/" + userName;
      this.http.get<ApiResponse<AccessList[]>>(accessListUrl)
      .subscribe(
          data => {
              if (data.succeeded == true) {
                this.setAccess(data.data!);
                let accessList = data.data!;
                this.accessListSubj.next(accessList);
              }
              return data;
          }
      );
  }

  impersonateUser(userRoleId: number) {
    if (userRoleId == undefined || userRoleId == 0) return;
    let accessListUrl = this.configServiceBaseUrl + "Access/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId
      + "/Impersonate/" + userRoleId;
    this.http.get<ApiResponse<AccessList[]>>(accessListUrl)
      .subscribe(
        data => {
          if (data.succeeded == true) {
            this.setAccess(data.data!);
            let accessList = data.data!;
            this.accessListSubj.next(accessList);
          }
          return data;
        }
      );
  }

  getTasktemplate() {
    let accessListUrl = this.configServiceBaseUrl + "TaskTemplate/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId;
      this.http.get<ApiResponse<TaskTemplate[]>>(accessListUrl)
      .subscribe(
          data => {
              if (data.succeeded == true) {
                let taskTemplateList = data.data!;
                this.taskTemplateSubj.next(taskTemplateList);
              }
              return data;
          }
      );
  }

  composeUserInfo(userName:string, userData: User[]) {
    const user = userData.find( d => d.userName?.toLowerCase() === userName?.toLowerCase());
    const fullName = user === undefined? '' : user?.firstName + ' ' + user?.lastName;
    return {user: user, fullName: fullName};
  }

  setAccess(data: AccessList[]): AccessControl {
    let result:AccessControl = new AccessControl();
    if (data.length > 0) {
      const accessList = data.filter(d => d.appClassId === APPCLASS_NOTE)[0].accessList;
      // 1 - read, 2 - edit, 4 - create, 8 - delete
      result.canCreate = (accessList & ACCESS_MASK_CREATE) != 0;
      result.canDelete = (accessList & ACCESS_MASK_DELETE) != 0;
      result.canEdit = (accessList & ACCESS_MASK_EDIT) != 0;
      result.canRead = (accessList & ACCESS_MASK_READ) != 0;
      result.canDownload = (accessList & ACCESS_MASK_DOWNLOAD) != 0;
    }
    this.acl = result;
    return result;
  }

  getCanCreate(): boolean | undefined{
    return this.acl?.canCreate;
  }

  getUserDefinedFields(lob: string){
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + "UIElement/UserDefinedElement/" + this.appConfig.productId + "/" + lob;
    let options = { headers: headers };

    this.http.get<ApiResponse<UserDefinedField[]>>(configUrl, options).subscribe(d => {
      if (d && d.succeeded) {
        this.userDefinedFields.next(d.data!);
      }
    });
  }

  getUIElementAttributes(lob: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + "UIElement/UIElementAttributes/" + this.appConfig.productId + "/" + lob;
    let options = { headers: headers };

    this.http.get<ApiResponse<UIElementAttributes[]>>(configUrl, options).subscribe(d => {
      if (d && d.succeeded) {
        this.uiElementAttributes.next(d.data!);
      }
    });
  }

  getLookupValueConfigByLob(lob: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + `Lookup/ValueConfig/Product/${this.appConfig.productId}/Customer/${this.appConfig.customerId}?lob=${lob}`;
    let options = { headers: headers };

    this.http.get<ApiResponse<LookupValueConfig[]>>(configUrl, options).subscribe(d => {
      if (d && d.succeeded) {
        this.lookupValueConfig.next(d.data!);
      }
    });
  }

  getUserHierarchy(userNames: string) {
    if (!userNames || userNames.length == 0) return;
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + `User/Hierarchy?userNames=${userNames}&productId=${this.appConfig.productId}`;
    let options = { headers: headers };

    this.http.get<ApiResponse<string>>(configUrl, options).subscribe(d => {
      if (d && d.succeeded) {
        this.userHierachySubj.next(d.data!);
      }
    });
  }

  getLetterConfigByHierarchyKey(hierarchyKey: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + `Letter/LetterConfig/Product/${this.appConfig.productId}/Customer/${this.appConfig.customerId}/HierarchyKey/${hierarchyKey}`;
    let options = { headers: headers };

    this.http.get<ApiResponse<LetterConfig[]>>(configUrl, options).subscribe(d => {
      if (d && d.succeeded) {
        this.letterConfig.next(d.data!);
      }
    });
  }

  authTypeChange(authType: string): void {
    this.AuthType.next(authType);
  }

  getInterqualSettings(): void {
    let interqualUrl = this.configServiceBaseUrl + "Partner?environment=" + this.environment;
    this.http.get<ApiResponse<InterqualSettings>>(interqualUrl).subscribe(
      data => {
        if (data.succeeded == true) {
            this.interqualSettings.next(data.data!);
        }
      }
    );
  }  

  updateUser(user: User){
    let updateUrl = this.configServiceBaseUrl + "User/Update";
    this.http.put<ApiResponse<User>>(updateUrl, user).subscribe(
      data => {
        if (data.succeeded == true && data.data) {
          this.userListByIdSubj.next([data.data]);      
        }
      }
    );
  }

  getDirectReports(): Observable<User[]> | undefined {
    const user = this.tokenService.getUser();
    let directReportUrl = this.configServiceBaseUrl + `User/DirectReports?userName=${user.profile.preferred_username}&productId=${this.appConfig.productId}`
    return this.http.get<ApiResponse<User[]>>(directReportUrl).pipe(
      map((response: ApiResponse<User[]>)=>{
        return response.data!;
      })
    );

    return;
  }

  getUserConfiguration(): Observable<UserConfiguration | undefined> {
    const user = this.tokenService.getUser();
    let userConfigUrl = this.configServiceBaseUrl + `User/Configuration?userName=${user.profile.preferred_username}&customerId=${this.appConfig.customerId}&productId=${this.appConfig.productId}`
    return this.http.get<ApiResponse<UserConfiguration>>(userConfigUrl).pipe(
      map((response: ApiResponse<UserConfiguration>)=>{
        if (response.succeeded){
          return response.data!;
        }
        return undefined;
      })
    );
  }

  getAppSettingConfig(): Observable<AppSettingsConfig[] | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let appConfigUrl = this.configServiceBaseUrl + "AppSetting/Config/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId;
    return this.http.get<ApiResponse<AppSettingsConfig[]>>(appConfigUrl, options).pipe(
      map((response: ApiResponse<AppSettingsConfig[]>)=>{
        return response.data;
      })
    );
  }

  getAllAuthorizationRoles(): Observable<AuthorizationRole[] | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
      let customListUrl = this.configServiceBaseUrl + "AuthorizationRole/AuthorizationRole";
      let queryParams = new HttpParams();
      queryParams = queryParams.append("productId", this.appConfig.productId);
      queryParams = queryParams.append("customerId", this.appConfig.customerId);
      return this.http.get<ApiResponse<AuthorizationRole[]>>(customListUrl, { headers: headers, params: queryParams }).pipe(
        map((response: ApiResponse<AuthorizationRole[]>)=>{
          if (response.succeeded){
            return response.data;
          }
          return undefined;
        })
      );
  }

  addUpdateAuthorizationRole(role: AuthorizationRole, action: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };
    let updateRoleUrl = this.configServiceBaseUrl + "AuthorizationRole/Update";
    this.http.put(updateRoleUrl, JSON.stringify(role), options).subscribe((authorizationRoleId) => {
      if (authorizationRoleId) {
        if (action == 'add') {
          this.eventService.RoleAdded(+authorizationRoleId)
        } else {
          this.eventService.RoleUpdatedEventCall();
        }
      }
    });
  }

  getAllRolePermissions(): Observable<RolePermissions[] | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let customListUrl = this.configServiceBaseUrl + "AuthorizationRole/AuthorizationRolePermissions";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("productId", this.appConfig.productId);
    return this.http.get<ApiResponse<RolePermissions[]>>(customListUrl, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<RolePermissions[]>)=>{
        if (response.succeeded){
          return response.data;
        }
        return undefined;
      })
    );
  }

  updateAuthorizationRolePermissions(authorizationRoleId: number, appClassId: number, permissions: number, isActive: boolean) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let updateRoleUrl = this.configServiceBaseUrl + "AuthorizationRole/UpdatePermissions";
    let user = this.tokenService.getUser();

    let queryParams = new HttpParams();
    queryParams = queryParams.append("productId", this.appConfig.productId);
    queryParams = queryParams.append("customerId", this.appConfig.customerId);
    queryParams = queryParams.append("authorizationRoleId", authorizationRoleId);
    queryParams = queryParams.append("appClassId", appClassId);
    queryParams = queryParams.append("permissions", permissions);
    queryParams = queryParams.append("isActive", isActive);
    queryParams = queryParams.append("updatedDate", new Date().toISOString());
    queryParams = queryParams.append("updatedBy", user.profile.preferred_username);
    this.http.post(updateRoleUrl, null, { headers: headers, params: queryParams }).subscribe(() => {
      this.eventService.PermissionsUpdated();
    });
  }

  getAllAppClasses(): Observable<AppClass[] | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let customListUrl = this.configServiceBaseUrl + "Access/GetAllAppClasses";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("productId", this.appConfig.productId);
    return this.http.get<ApiResponse<AppClass[]>>(customListUrl, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<AppClass[]>)=>{
        if (response.succeeded){
          return response.data;
        }
        return undefined;
      })
    );
  }

  validateAuthorizationRole(authorizationRoleId: number): Observable<number | undefined> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let customListUrl = this.configServiceBaseUrl + "AuthorizationRole/ValidateRole";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("authorizationRoleId", authorizationRoleId);
    
    return this.http.get<ApiResponse<number>>(customListUrl, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<number>)=>{
        if (response.succeeded){
          return response.data;
        }
        return undefined;
      })
    );
  }
  //SELF SERVE METHODS
  getEntityHierarchyTypes(){
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let customListUrl = this.configServiceBaseUrl + "CustomerEntity/HierarchyType/Customer/" + this.appConfig.customerId;
    
    return this.http.get<ApiResponse<EntityHierarchyType[]>>(customListUrl, { headers: headers }).pipe(
      map((response: ApiResponse<EntityHierarchyType[]>)=>{
        if (response.succeeded){
          return response.data;
        }
        return undefined;
      })
    );
  }

  getCustomerEntities(entityHierarchyTypeId: number | null){
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let customListUrl = this.configServiceBaseUrl + "CustomerEntity/Product/" + this.appConfig.productId + "/Customer/" + this.appConfig.customerId;
    
    let queryParams = new HttpParams();
    queryParams = queryParams.append("entityHierarchyTypeId", entityHierarchyTypeId??'');

    return this.http.get<ApiResponse<CustomerEntity[]>>(customListUrl, { headers: headers, params: queryParams }).pipe(
      map((response: ApiResponse<CustomerEntity[]>)=>{
        if (response.succeeded){
          return response.data;
        }
        return undefined;
      })
    );
  }

  getNoteTypeLookupValueConfig(lookupSetGuid: string):Observable<LookupValueConfig[]|void>  {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + `Lookup/ValueConfig/Product/${this.appConfig.productId}/Customer/${this.appConfig.customerId}?lookupSetGUID=${lookupSetGuid}`;
    let options = { headers: headers };

    return this.http.get<ApiResponse<LookupValueConfig[]>>(configUrl, options).pipe(
      map((data)=>{
        if (data.succeeded && data.data){
          this.NoteTypeLookupValueConfig = data.data;
        }
        return data.data;
      }));
  }

  getAllNoteTypes():Observable<NoteType[]|void>  {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });

    let configUrl = this.configServiceBaseUrl + `SelfServe/GetNoteTypes`;
    let options = { headers: headers };

    return this.http.get<ApiResponse<NoteType[]>>(configUrl, options).pipe(
      map((data)=>{
        this.NoteTypeCache = data.data;
        return data.data;
      }));
  }
  //END SELF SERVE
}
