import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, map } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api/apiResponse";
import { AppSettings } from "../shared/appsettings";
import { MemberEligibility } from "@CommonLib/models/member/memberEligibility";
import { MemberService } from "@CommonLib/services/member-service.service";

export interface EligibilityParams
{
    mvdId:string;
    authIntakeId: number;
    productId: number;
    customerId: number;
    providerNPI: string;
}

@Injectable({
  providedIn: 'root'
})

export class EligibilityService {
  private eligServiceBaseUrl!: string;

  constructor(private http: HttpClient, private appConfig: AppSettings, private memberService: MemberService) {
    this.eligServiceBaseUrl = this.appConfig.eligibilityServiceBaseUrl;
  }

  private eligApiResponse = new BehaviorSubject<boolean>(false);
  EligApiResponse = this.eligApiResponse.asObservable();

  getMemberEligibility(mvdId: string, authIntakeId: number, providerNPI: string) {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    const productId = this.appConfig.productId;
    const customerId = this.appConfig.customerId;
    let eligParams: EligibilityParams = {
      mvdId : mvdId,
      authIntakeId : authIntakeId,
      productId: productId,
      customerId: customerId,
      providerNPI: providerNPI,
    }

    let eligUrl = this.eligServiceBaseUrl + "Availity/GetMemberEligibility";
    return this.http.post<ApiResponse<MemberEligibility[]>>(eligUrl, eligParams, { headers: headers } ).pipe(
      map((response: ApiResponse<MemberEligibility[]>) => {
        if (response.succeeded == true) {
          if (response.message?.length > 0) {
            this.eligApiResponse.next(true);
          }
          else {
            this.eligApiResponse.next(false);
          }
          this.memberService.setRealTimeEligibility(response.data!);
          return response;
        } else {
          console.log("RTE Error:", response.message);
          return undefined;
        }
      }
    ));
  }
}
