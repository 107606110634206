import { EventEmitter, Injectable } from "@angular/core";
import { UserAttribute } from "@CommonLib/models/configuration/userAttribute";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    public DueDateResetEvent = new EventEmitter();
    public TimeZoneEvent = new EventEmitter();
    public RequestTypeChangedEvent = new EventEmitter();
    public ProcedureAddedEvent = new EventEmitter();
    public AuthSavedEvent = new EventEmitter();
    public AuthClosedEvent = new EventEmitter();
    public SaveButtonStateEvent = new EventEmitter<boolean>();
    public QuequeAddedEvent = new EventEmitter<UserAttribute>();
    public QueueRefreshedEvent = new EventEmitter();
    public NoteTypeUpdatedEvent = new EventEmitter();
    public RoleUpdatedEvent = new EventEmitter();
    public PermissionsUpdatedEvent = new EventEmitter();
    public NewRoleAdded = new EventEmitter<number>()
    public LookupUpdatedEvent = new EventEmitter();

    public DueDateReset(){
        this.DueDateResetEvent.emit();
    }

    public TimeZone(){
        this.TimeZoneEvent.emit();
    }

    public RequestTypeChanged(){
        this.RequestTypeChangedEvent.emit();
    }

    public ProcedureAdded(){
        this.ProcedureAddedEvent.emit();
    }

    public AuthSaved(){
        this.AuthSavedEvent.emit();
    }

    public AuthClosed(){
        this.AuthClosedEvent.emit();
    }

    SaveButtonState(isEnabled: boolean){
        this.SaveButtonStateEvent.emit(isEnabled);
    }

    public QueueAdded(userAttribute: UserAttribute){
        this.QuequeAddedEvent.emit(userAttribute);
    }

    public QueueRefreshed(){
        this.QueueRefreshedEvent.emit();
    }

    public NoteTypeUpdated() {
        this.NoteTypeUpdatedEvent.emit();
    }

    public RoleUpdatedEventCall(){
        this.RoleUpdatedEvent.emit();
    }

    public PermissionsUpdated(){
        this.PermissionsUpdatedEvent.emit();
    }

    public RoleAdded(authorizationRoleId: number){
        this.NewRoleAdded.emit(authorizationRoleId);
    }

    public LookupUpdated() {
        this.LookupUpdatedEvent.emit();
    }
}