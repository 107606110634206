import { Injectable } from "@angular/core";
import { ApiResponse } from "@CommonLib/models/apiResponse";
import { BehaviorSubject, map, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventService } from "@CommonLib/services/events.service";
import { UserAttribute } from "@CommonLib/models/configuration/userAttribute";
import { LookupValueConfig, LookupValueExtensionData, TemplateExtensions } from "../models/configuration/lookupValueConfig";
import { LookupValue } from "../models/Lookups/lookupValue";
import { LookupService } from "./lookup.service";
import { NoteType, NoteTypeConfig } from "../models/configuration/noteType";
import { AppSettings } from "../shared/appsettings";
import { TokenStorageService } from "./token-storage.service";
import { CustomLinks } from "../models/configuration/customLinks";

@Injectable({
    providedIn: 'root'
})

export class SelfServeService {
    private configServiceBaseUrl: string = '';
    private lookupServiceBaseUrl: string = '';
    private allQueues: UserAttribute[] | undefined = [];
    private user: any;
    public noteTypeEntities: NoteType[] = [];
    public customLinks = new BehaviorSubject<CustomLinks[]>([]);

    constructor(private http: HttpClient, private appConfig: AppSettings, private eventService: EventService, private lookupService: LookupService,
        private tokenStorage: TokenStorageService) {
        this.configServiceBaseUrl = this.appConfig.configServiceBaseUrl;
        this.lookupServiceBaseUrl = this.appConfig.lookupServiceBaseUrl;
        this.user = this.tokenStorage.getUser();
    }

    getUserAttributes(): Observable<UserAttribute[] | undefined> {
        let groupListUrl = this.configServiceBaseUrl + "UserAttribute?productId=" + this.appConfig.productId;

        return this.http.get<ApiResponse<UserAttribute[]>>(groupListUrl).pipe(
            map((response: ApiResponse<UserAttribute[]>) => {
                if (response.succeeded) {
                    this.allQueues = response.data;
                    return response.data;
                }
                return undefined;
            })
        );
    }

    async addUpdateUserAttribute(userAttribute: UserAttribute) {
        let headers = new HttpHeaders({ 'x-api-version': '1.0' });
        let options = { headers: headers };

        let queueUpdate = this.configServiceBaseUrl + "UserAttribute/Update";

        let queryParams = new HttpParams();
        queryParams = queryParams.append("userAttribute", JSON.stringify(userAttribute));

        this.http.post(queueUpdate, JSON.stringify(userAttribute), options).subscribe(() => {
            this.eventService.QueueRefreshed();
        });
    }

    isDuplicate(queue: UserAttribute): boolean {
        if (this.allQueues) {
            const exists = this.allQueues.find(q => q.customerAttributeName.toLowerCase() == queue.customerAttributeName.toLowerCase())
            if (exists) {
                return true;
            }
        }
        return false;
    }

    isDuplicateSAML(queue: UserAttribute): boolean {
        if (this.allQueues) {
            const exists = this.allQueues.find(q => (q.samlClaimValue == null ? '' : q.samlClaimValue.toLowerCase()) == (queue.samlClaimValue == null ? '' : queue.samlClaimValue.toLowerCase()))
            if (exists) {
                return true;
            }
        }
        return false;
    }

    //Note Type Self Serve methods.
    addUpdateNoteType(config: LookupValueConfig) {
        let headers = new HttpHeaders({ 'x-api-version': '1.0' });
        let options = { headers: headers };

        let queueUpdate = this.configServiceBaseUrl + "SelfServe/MergeNoteTypeConfig";

        this.http.post(queueUpdate, JSON.stringify(config), options).subscribe(() => {
            this.eventService.NoteTypeUpdated();
        });
    }

    isNoteTypeDuplicate(newName: string): boolean {
        var lookupSet = this.lookupService.LookupSet.find(l => l.lookupSetName.toLowerCase() == 'note type');
        var existing = this.lookupService.AllLookupValues.find(l => l.lookupStandardLabel.toLowerCase().trim() == newName.toLowerCase().trim() && l.lookupSetID == lookupSet?.lookupSetID);
        if (existing) {
            return true;
        }
        return false;
    }

    mergeLookupValue(lookup: LookupValue) {
        let headers = new HttpHeaders({ 'x-api-version': '1.0' });
        let options = { headers: headers };

        let lookupUpdate = this.lookupServiceBaseUrl + "LookupValue/MergeLookupValue";

        this.http.post(lookupUpdate, JSON.stringify(lookup), options).subscribe(() => {
            this.eventService.LookupUpdated();
            this.lookupService.getAllLookupValues(true);
        });
    }

    saveTemplate(currentConfig: NoteTypeConfig, lookupValueGUID: string, lookupValueConfigId: number) {
        var lookupSet = this.lookupService.LookupSet.find(l => l.lookupSetName.toLowerCase() == 'note type');
        if (currentConfig && lookupSet) {
            var newConfig = new LookupValueConfig();
            newConfig.id = lookupValueConfigId;
            newConfig.customerId = this.appConfig.customerId;
            newConfig.lookupSetExtensionId = currentConfig.lookupSetExtensionId ?? 0;
            newConfig.productId = this.appConfig.productId;
            newConfig.entityId = currentConfig.entityId;
            newConfig.lookupSetGUID = lookupSet.lookupSet_GUID;
            newConfig.lookupValueGUID = lookupValueGUID;
            if (currentConfig.extensionData) {
                var ext: TemplateExtensions = JSON.parse(currentConfig.extensionData!);
                var tempExt = new TemplateExtensions();
                tempExt.template = ext.template;
                tempExt.templateUrl = null;
                tempExt.workflow = 'AuthIntakeForm';
                var extData = new LookupValueExtensionData();
                extData.templateExtensions.push(tempExt);
                newConfig.extensionData = extData;
            }
            newConfig.createdBy = this.user.profile.preferred_username;
            newConfig.createdDate = new Date().toISOString();
            newConfig.updatedBy = this.user.profile.preferred_username;
            newConfig.updatedDate = new Date().toISOString();
            newConfig.isActive = true;
            newConfig.startDate = null;
            newConfig.endDate = null;
            this.addUpdateNoteType(newConfig);
        }
    }

    //Quick Links Self Serve
    getCustomLinks(refresh: boolean = false): Observable<ApiResponse<CustomLinks[]> | undefined> {
        let headers = new HttpHeaders({ 'x-api-version': '1.0' });
        let customListUrl = this.configServiceBaseUrl + "CustomData";
        let queryParams = new HttpParams();
        queryParams = queryParams.append("productId", this.appConfig.productId);
        queryParams = queryParams.append("customerId", this.appConfig.customerId);
        queryParams = queryParams.append("refresh", refresh);
        return this.http.get<ApiResponse<CustomLinks[]>>(customListUrl, { headers: headers, params: queryParams }).pipe(
            map((response: ApiResponse<CustomLinks[]>) => {
                this.customLinks.next(response.data!);
                return response;
            })
        );
    }

    mergeCustomLinks(customLink: CustomLinks) {
        let headers = new HttpHeaders({ 'x-api-version': '1.0' });
        let options = { headers: headers };

        let customListUrl = this.configServiceBaseUrl + "CustomData/MergeCustomLinks";

        this.http.post(customListUrl, JSON.stringify(customLink), options).subscribe(() => {
            this.getCustomLinks(true).subscribe();
        });
    }

    isDuplicateLink(newName: string, customLink: string, title: string, existingId: number): string {
        var errorMessage = '';
        var links = this.customLinks.value;
        var dup = links.find(c => c.displayName.trim().toLowerCase() == newName.trim().toLowerCase());
        if (dup && dup.customLinkId !== existingId) {
            errorMessage += `* ${newName} already exists.<br/>`;
        }
        dup = links.find(c => c.title.trim().toLowerCase() == title.trim().toLowerCase());
        if (dup && dup.customLinkId !== existingId) {
            errorMessage += `* ${title} already exists.<br/>`;
        }
        dup = links.find(c => c.customLink.trim().toLowerCase() == customLink.trim().toLowerCase());
        if (dup && dup.customLinkId !== existingId) {
            errorMessage += `* This link already exists.<br/>`;
        }
        return errorMessage;
    }
}