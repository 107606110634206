import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FaxQueueItem } from 'projects/um/src/app/models/fax/faxQueueItem';
import { ACCESS_MASK_CREATE, ACCESS_MASK_DELETE, ACCESS_MASK_DOWNLOAD, ACCESS_MASK_EDIT, ACCESS_MASK_READ, DATE_WITH_SECONDS_AMPM } from '../../../../../assets/constants';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { FormService } from 'projects/um/src/app/services/form-service.service';
import { SignalrService } from 'projects/um/src/app/services/signalr.service';
import { ConfirmDialogComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { AppSettings } from '../../../appsettings';
import { AccessControl } from 'projects/um/src/app/models/rbac/accessControl';

@Component({
  selector: 'app-fax-queue',
  templateUrl: './fax-queue.component.html',
  styleUrls: ['./fax-queue.component.scss']
})
export class FaxQueueComponent implements OnInit {
  @Input() item!: FaxQueueItem;
  @Output() removeFaxes = new EventEmitter<{pdfId: string, action: string}>();

  dateFormat = DATE_WITH_SECONDS_AMPM;
  splittingFax = false;
  selectedFax!: {pdfId: string, action: string};
  userConfig: any;
  timezone: string = '';
  userRbac: any;
  rbac!: AccessControl;

  constructor(private tokenStorage: TokenStorageService, private formService: FormService, private signalR : SignalrService, private confirmDialog: ConfirmDialogComponent,
      private appSettings: AppSettings) {
    this.userConfig = this.tokenStorage.getUserConfig();
    this.timezone = this.appSettings.timeZone;
  }

  ngOnInit(): void {
    this.populateUserAccess();
  }

  populateUserAccess(){
    this.userRbac = this.tokenStorage.getUserRbac();
    if (this.userRbac && this.userRbac.length > 0){
      let result: AccessControl = new AccessControl();
        const accessList = this.userRbac.filter((d: any) => d.appClassDescription.toLowerCase() === 'unlockfax')[0]?.accessList;
        if (accessList) {
          // 1 - read, 2 - edit, 4 - create, 8 - delete
          result.canCreate = (accessList & ACCESS_MASK_CREATE) != 0;
          result.canDelete = (accessList & ACCESS_MASK_DELETE) != 0;
          result.canEdit = (accessList & ACCESS_MASK_EDIT) != 0;
          result.canRead = (accessList & ACCESS_MASK_READ) != 0;
          result.canDownload = (accessList & ACCESS_MASK_DOWNLOAD) != 0;
        }
        this.rbac = result;
    }
  }

  onChange($event: any) {
    if ($event.checked) {
      this.selectedFax = {pdfId:$event.source.value, action:'add'};
    } else {
      this.selectedFax = {pdfId:$event.source.value, action:'remove'};
    }
    this.removeFaxes.emit(this.selectedFax);
  }

  unlockFax(item: FaxQueueItem){
	if (item.locked) {
		const dialogRef = this.confirmDialog.open({
			title: 'Unlock Fax',
			message: `Are you sure you want to unlock this fax?`,
			acceptButtonText: 'Yes',
			declineButtonText: 'No'
		});

		dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
			if (item){
				this.formService.unlockFax(item.internalId);
				this.signalR.broadcastMessage(`unlocked:${item.internalId}`);
				this.signalR.clientMessage(`Fax ${item.name} has been unlocked by Supervisor`, item.lockedByUserName);
			}
			dialogRef.close();
		});

		dialogRef.componentInstance.clickDeclineButton.subscribe(() => {
			dialogRef.close();
		})
	}
  }
}
