<div class="fax-queue-container">
	<div class="no-label-list">
		<span style="width: 20px;">
			<mat-icon *ngIf="item.locked" class="material-icons-outlined icon icon-dark va-middle icon-size-sm"
								aria-label="Lock" matTooltip='Locked by {{ item.lockedBy }} - {{ item.lockDate | date: dateFormat }}'
								[matMenuTriggerFor]="faxMenu" [matMenuTriggerData]="{item: item}">
				<span>lock</span>
			</mat-icon>
		</span>
		<mat-checkbox matTooltip="Completed" matTooltipPosition="after" matTooltipClass="tooltip" aria-label="Completed"
									(change)="onChange($event)" [value]="item.internalId">
		</mat-checkbox>
		<button mat-button title="Process {{ item.name }} - {{ item.p8DocName }}"
						(click)="dialogFax.openProcessSplitFax(item)" *ngIf="!splittingFax">
			<span class="value truncate" aria-label="Date" [ngClass]="item.color">{{ item.name }}</span>
			<span class="value" aria-label="Time" [ngClass]="item.color">{{ item.date | date: dateFormat : timezone}}</span>
		</button>
		<button mat-button title="Process {{ item.name }} - {{ item.p8DocName }}"
						(click)="dialogFax.openProcessSplitFax(item)" *ngIf="splittingFax" disabled>
			<mat-icon class="material-icons-outlined icon-dark" aria-label="Split file icon">content_copy</mat-icon>
			<span class="value" aria-label="Splitting">Splitting Fax...</span>
		</button>
		<ul *ngIf="item['split'].length">
			<li *ngFor="let subItem of item['split']">
				<span style="width: 20px;">
					<mat-icon *ngIf="subItem.locked" class="material-icons-outlined icon icon-dark va-middle icon-size-sm"
										aria-label="Lock"
										matTooltip='Fax locked by {{ subItem.lockedBy }} - {{ subItem.lockDate | date: dateFormat }}'
										[matMenuTriggerFor]="faxMenu" [matMenuTriggerData]="{item: subItem}">
						<span>lock</span>
					</mat-icon>
				</span>
				<button mat-button title="Attach {{ subItem.name }}" (click)="dialogFax.openProcessSplitFax(subItem)">
					<mat-icon class="material-icons-outlined icon-dark" aria-label="Split file icon">insert_page_break</mat-icon>
					<span class="value" aria-label="Date" [ngClass]="subItem.color">{{ subItem.name }}</span>
					<span class="value" aria-label="Time" [ngClass]="subItem.color">{{ subItem.date | date: dateFormat : timezone}}</span>
				</button>
			</li>
		</ul>
	</div>
</div>
<app-dialog-fax #dialogFax></app-dialog-fax>

<!-- standard material menu -->
<mat-menu #faxMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<button mat-menu-item (click)="unlockFax(item)" [controlBehavior]="'UnlockFax'">Unlock Fax</button>
	</ng-template>
</mat-menu>