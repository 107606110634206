<mat-toolbar class="site-header">
  <div data-hide-gt-sm>
    <button mat-icon-button aria-label="Menu" (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div data-layout="row" data-layout-align="start center">
    <div class="product-dropdown">
      <img src="/assets/img/logo-affinite-um-new.svg" class="product-logo um" alt="Affinite UM logo" title="Affinite UM Home" aria-label="Affinite UM Home" />
      <!-- <mat-menu class="product-switcher" #productMenu="matMenu" xPosition="before">
        <a routerLink="/" mat-menu-item class="product-logo-link">
          <img src="/assets/img/logo-affinite-cm-new.svg" class="product-logo cm" alt="Affinite CM logo" title="Affinite CM Home" aria-label="Affinite CM Home" />
        </a>
      </mat-menu> -->
    </div>
    <!-- <div class="product-dropdown-icon" mat-button [matMenuTriggerFor]="productMenu">
      <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Dropdown arrow">arrow_drop_down</mat-icon>
    </div> -->
  </div>
  <div data-flex data-layout="row" data-layout-align="end" data-hide-lt-md>
    <ul data-layout="row" data-layout-gap="15px" class="nav-items">
      <li>
        <a routerLink="/" routerLinkActive="active">
          <mat-icon class="material-icons-round icon" aria-hidden="false" aria-label="Home">dashboard</mat-icon>
          <span class="nav-item">Home</span>
        </a>
      </li>
      <li>
        <a routerLink="/reporting" routerLinkActive="active" [ngClass]="{'disabled': !reportingMenu }">
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Reporting">area_chart</mat-icon>
          <span class="nav-item">Reporting</span>
        </a>
      </li>
      <li>
        <a routerLink="/fax-archive" routerLinkActive="active" [ngClass]="{'disabled': !faxArchiveMenu }">
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Fax Archive">fax</mat-icon>
          <span class="nav-item">Fax Archive</span>
        </a>
      </li>
      <li>
        <!-- change to button once controlBehavior issue is resolved -->
        <a [matMenuTriggerFor]="adminMenu" [controlBehavior]="'AdminMenu'">
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Admin">settings</mat-icon>
          <span class="nav-item">Admin</span>
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Dropdown arrow">arrow_drop_down</mat-icon>
        </a>
      </li>
      <li>
        <button type="button" [matMenuTriggerFor]="linksMenu">
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Links">link</mat-icon>
          <span class="nav-item">Links</span>
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Dropdown arrow">arrow_drop_down</mat-icon>
        </button>
      </li>
      <li>
        <!--<a routerLink="/signout-callback" routerLinkActive="active">-->
        <button type="button" (click)="logout()">
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="Log out">logout</mat-icon>
          <span class="nav-item">Log Out</span>
        </button>
        <!--</a>-->
      </li>
      <li>
        <button type="button" [matMenuTriggerFor]="historyMenu" #historyMenuTrigger="matMenuTrigger" title="View recently viewed/completed auths" aria-label="View recently viewed/completed auths">
          <mat-icon class="material-icons-outlined icon" aria-hidden="false" aria-label="History">history</mat-icon>
        </button>
      </li>
    </ul>
    <mat-menu #adminMenu="matMenu">
      <button routerLink="/supervisor-tools" mat-menu-item [controlBehavior]="'SupervisorTools'">Supervisor Tools</button>
      <button mat-menu-item (click)="openUnlockAuthDialog()" [controlBehavior]="'UnlockAuthMenu'">Unlock Auth</button>
      <button mat-menu-item (click)="openAuthResubmission()" [controlBehavior]="'AuthorizationResubmission'">Auth Resubmission Tool</button>
      <button mat-menu-item (click)="openConfigurations()" [controlBehavior]="'Configuration'" *ngIf="configurations">Configurations</button>
      <button mat-menu-item (click)="openImpersonateRoleDialog()" [controlBehavior]="'ImpersonateMenu'">Impersonate Role</button>
      <!--<button mat-menu-item (click)="sendMessage()" *ngIf="superUser">Send Message</button>-->
    </mat-menu>
    <mat-menu #linksMenu="matMenu">
      <button mat-menu-item title="InterQual Guidelines" (click)="openPortal(extId)" *ngIf="externalGuidelines">InterQual&reg;</button>
      <button mat-menu-item title="Appeal" (click)="appealDecision()">Appeal</button>
      <div *ngFor="let link of customLinks">
        <a mat-menu-item href={{link.customLink}} title={{link.title}} target={{link.linkTarget}}>
          <img *ngIf="link.linkImagePath" src={{link.linkImagePath}} class="logo-cms-gov" alt={{link.displayName}} />
          <span *ngIf="!link.linkImagePath" class="text-link">{{ link.displayName }}</span>
        </a>
      </div>
    </mat-menu>
    <mat-menu #historyMenu="matMenu" class="auto-width">
      <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <mat-tab-group class="compact" fitInkBarToContent="true" animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Recently Viewed">
            <ng-container *ngTemplateOutlet="recentListTemplate; context: { list: recentEventsViewed }">
            </ng-container>
          </mat-tab>
          <mat-tab label="Recently Completed">
            <ng-container *ngTemplateOutlet="recentListTemplate; context: { list: recentEventsClosed }">
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>

<!-- Recently Viewed/Completed Templates -->
<ng-template #recentListTemplate let-list="list">
  <div class="recent-activity" *ngFor="let data of list">
    <ng-container *ngTemplateOutlet="recentItemTemplate; context: { $implicit: data }">
    </ng-container>
  </div>
</ng-template>
<ng-template matTabContent #recentItemTemplate let-data>
  <div class="item">
    <div class="data-list text-sm">
      <ul>
        <li>
          <div>
            <span class="label cdk-visually-hidden">Member Name</span>
            <span class="value"><span class="data-header text-sm">{{ data.memberName }}</span></span>
          </div>
          <div class="nowrap">
            <span class="label">Member ID</span>
            <span class="value">
              <a href="#" (click)="historyMenuTrigger.closeMenu(); redirect('member',data.mvdId,'')"  [routerLink]="['']">{{ data.memberId }}</a>
            </span>
            <span class="label ml-3-pct">Auth #</span>
            <span class="value">
              <a href="#" (click)="historyMenuTrigger.closeMenu(); redirect('authorization', data.authIntakeId, data.authNumber.split('-')[0])"  [routerLink]="['']">{{ data.authNumber }}</a>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<!-- Dialog Content: Edit Auth -->
<ng-template #impersonateDialogTemplate>
  <form>
    <mat-form-field appearance="fill" class="no-hint" style="width: 250px">
      <mat-label>Impersonate Role</mat-label>
      <mat-select required [(ngModel)]="impersonateRoleId" [ngModelOptions]="{standalone: true}">
        <mat-option [value]=7>UM Intake</mat-option>
        <mat-option [value]=8>UM Clinician</mat-option>
        <mat-option [value]=9>UM Manager</mat-option>
        <mat-option [value]=10>Medical Director</mat-option>
        <mat-option [value]=11>Pharmacist</mat-option>
        <mat-option [value]=12>Support Staff</mat-option>
        <mat-option [value]=13>Customer Service Rep</mat-option>
        <mat-option [value]=14>UM Research Reviewer</mat-option>
        <mat-option [value]=15>IT Support</mat-option>
        <mat-option [value]=16>Super User</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-template>

<!-- Dialog Content: Unlock Auth-->
<ng-template #unlockAuthDialogTemplate>
  <form>
    <mat-form-field appearance="fill" [class.mat-form-field-invalid]="invalidAuthNumber" class="full-width">
      <mat-label>Authorization Number</mat-label>
      <input matInput required [value]="lockedAuthNum" placeholder="0001-123456" [(ngModel)]="lockedAuthNum" [ngModelOptions]="{standalone: true}">
      <mat-hint *ngIf="invalidAuthNumber">
        {{validationMsg}}
      </mat-hint>
    </mat-form-field>
  </form>
</ng-template>

<!-- Dialog Content: Resubmit Auth-->
<ng-template #resubmitAuthDialogTemplate>
  <form>
    <div class="alert alert-danger" *ngIf="invalidMemberId">
      <span>{{memberIdValidationMsg}}</span>
    </div>
    <div class="alert alert-danger" *ngIf="invalidAuthNumber">
      <span>{{validationMsg}}</span>
    </div>
    <div class="alert alert-danger" *ngIf="validationError">
      <span>{{validationError}}</span>
    </div>
    <mat-form-field appearance="fill" [class.mat-form-field-invalid]="invalidAuthNumber" class="full-width">
      <mat-label>Member ID</mat-label>
      <input matInput required [value]="resubmitMemberId" [(ngModel)]="resubmitMemberId" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field appearance="fill" subscriptSizing="dynamic" [class.mat-form-field-invalid]="invalidAuthNumber" class="full-width mb-30">
      <mat-label>Authorization Number</mat-label>
      <input matInput required [value]="resubmitAuthNum" placeholder="001-123456" [(ngModel)]="resubmitAuthNum" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <div>
      <mat-checkbox (change)="overrideChanged($event, 'override')">Override Edit Status?</mat-checkbox>
    </div>
    <div>
      <mat-checkbox (change)="overrideChanged($event, 'ssc')">Send as revised to SSC?</mat-checkbox>
    </div>
  </form>
</ng-template>
