import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../shared/appsettings';
import { LookupValue } from "../models/Lookups/lookupValue";
import { ApiResponse } from '../models/api/apiResponse';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Procedure } from '../models/Lookups/procedure';
import { Diagnosis } from '../models/Lookups/diagnosis';
import { Provider } from '../models/Lookups/provider';
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { PROVIDER_TYPE, TASKSTATUS_PENDING, TASKSTATUS_SUCCESSFUL, TASKSTATUS_UNSUCCESSFUL } from '../../assets/constants';
import { ConfigService } from './config-service.service';
import { AuthIntakeForm } from '../models/authForm/authIntakeForm';
import { DialogService } from '../shared/guards/dialog.service';
import { State } from '../models/Lookups/state';
import Swal from 'sweetalert2';
import { DueDateRuleChangeStatus } from '../models/Lookups/dueDateRuleChangeStatus';
import { EligibilityHelperService } from './eligibility-helper.service';
import { MemberService } from '@CommonLib/services/member-service.service';
import { LookupSet } from '../models/Lookups/lookupSet';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private lookupServiceBaseUrl!: string;

  private lookupDataSet = new BehaviorSubject<LookupValue[]>([]);
  LookupData = this.lookupDataSet.asObservable(); 
  private procList = new BehaviorSubject<Procedure[]>([]);
  ProcedureList = this.procList.asObservable();

  private diagList = new BehaviorSubject<Diagnosis[]>([]);
  DiagnosisList = this.diagList.asObservable();

  private provList = new BehaviorSubject<Provider[]>([]);
  ProviderList = this.provList.asObservable();

  private stateList = new BehaviorSubject<State[]>([]);
  StateList = this.stateList.asObservable();

  private selectedProvList = new BehaviorSubject<Provider[]>([]);
  selectedProviderList = this.selectedProvList.asObservable();

  public udfLookupValues = new Subject<LookupValue[]>();

  public TaskSuccessIds = new Subject<number[]>();
  public TaskUnsuccessfulIds = new Subject<number[]>();
  public TaskPendingIds = new Subject<number[]>();
  private baseLookupSet: LookupValue[] = [];
  public ReqProviderTemp = new BehaviorSubject<boolean>(false);
  public SvcProviderTemp = new BehaviorSubject<boolean>(false);
  public selectedReqProvider: Provider | null = null;
  public selectedSvcProvider: Provider | null = null;

  public RequestorTypes = new BehaviorSubject<LookupValue[]>([]);
  public RequestTypes = new BehaviorSubject<LookupValue[]>([]);
  public UrgencyTypes = new BehaviorSubject<LookupValue[]>([]);
  public TreatmentTypes = new BehaviorSubject<LookupValue[]>([]);
  public TATUnitTypes = new BehaviorSubject<LookupValue[]>([]);
  public TATStatus = new BehaviorSubject<LookupValue[]>([]);
  public TATStatusList = new BehaviorSubject<DueDateRuleChangeStatus[]>([]);

  public LookupSet: LookupSet[] = [];
  public AllLookupValues: LookupValue[]=[];

  constructor(private http: HttpClient, private config: AppSettings, private configService: ConfigService, private dialogService: DialogService,
    private eligibilityHelperService: EligibilityHelperService, private memberService: MemberService
  ) {
    this.lookupServiceBaseUrl = config.lookupServiceBaseUrl;
  }

  public getLookupValueId(lookupStandardValue:string, lookupSetId: number|undefined=undefined):number|null{
    var lookupId = undefined;
    if (lookupSetId){
      lookupId = this.lookupDataSet.value.find(l => l.lookupStandardValue.toLowerCase() == lookupStandardValue.toLowerCase() && l.lookupSetID == lookupSetId);
    } else {
      lookupId = this.lookupDataSet.value.find(l => l.lookupStandardValue.toLowerCase() == lookupStandardValue.toLowerCase());
    }

    if (lookupId){
      return lookupId.lookupValueID;
    }
    return null;
  }

  searchProvidersLive(searchString: string, skip: number) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/SearchProviders";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("skip", skip*100);
    queryParams = queryParams.append("take", 100);
    queryParams = queryParams.append("searchBy", searchString)
    return this.http.get<ApiResponse<Provider[]>>(lookupUrl, { params: queryParams });//.subscribe(data => {
  }

  searchProcedures(searchString: string) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetProcedures";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("skip", 0);
    queryParams = queryParams.append("take", 100);
    queryParams = queryParams.append("searchBy", searchString)
    return this.http.get<ApiResponse<Procedure[]>>(lookupUrl, { params: queryParams });//.subscribe(data => {
  }

  getProcedures(procedures: string[]) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetProceduresByCode";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("procedureCode", procedures.toString())
    return this.http.get<ApiResponse<Procedure[]>>(lookupUrl, { params: queryParams });//.subscribe(data => {
  }

  searchDiagnosis(searchString: string) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetDiagnosis";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("skip", 0);
    queryParams = queryParams.append("take", 100);
    queryParams = queryParams.append("searchBy", searchString)
    return this.http.get<ApiResponse<Diagnosis[]>>(lookupUrl, { params: queryParams });//.subscribe(data => {
  }

  populateSelectedProviders(authForm: AuthIntakeForm) {
    let selectedProviders: Provider[] = [];
    if (authForm.authIntakeHeader.reqFinalProviderHashId) {
      this.getProviderByHashId(authForm.authIntakeHeader.reqFinalProviderHashId, authForm.authIntakeDetail[0].authDetailId, PROVIDER_TYPE.REQUESTING).subscribe((res) => {
        if (res.succeeded && res.data) {
          let type = this.lookupDataSet.value.filter(t => t.lookupValueID == 10041)[0]?.lookupStandardLabel;
          this.ReqProviderTemp.next(res.data.isTempProvider);
          res.data.type = type;
          selectedProviders.push(res.data);
          this.selectedReqProvider = res.data;
          if (res.data.providerInfoChanged){
            authForm.authIntakeHeader.reqFinalProviderHashId = res.data.hashId;
            Swal.fire({
              title: 'Requesting Provider Updated',
              text: `Requesting Provider Information has been updated. Please verify the correct provider is selected.`,
              icon: 'info', //possible values - error/success/info/question
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: '#5890a3',
              confirmButtonText: 'OK'
            });
          }
          this.selectedProvList.next(selectedProviders);

        } else if (res.succeeded && !res.data) { //The provider info has updated and the hashId no longer exists. We cannot retrieve provider info reliably. Display message for user to select provider again
          Swal.fire({
            title: 'Provider Not Found',
            text: `Requesting provider not found. Please search for provider again using NPI ${authForm.authIntakeHeader.reqNPI}`,
            icon: 'error', //possible values - error/success/info/question
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#5890a3',
            confirmButtonText: 'OK'
          });
        }
      });
    }
    else if (authForm.authIntakeHeader.reqNPI && authForm.authIntakeHeader.reqNPI) {
      this.getProviderByProviderInfo(authForm.authIntakeHeader.reqNPI, authForm.authIntakeHeader.reqServiceLocationID,authForm.authIntakeDetail[0].authDetailId).subscribe((res) => {
        if (res.succeeded && res.data && res.data.length > 0) {
          let type = this.lookupDataSet.value.filter(t => t.lookupValueID == 10041)[0]?.lookupStandardLabel;
          let uniqueProvider = res.data.find(p => p.hashId == authForm.authIntakeHeader.reqFinalProviderHashId);
          if (uniqueProvider) {
            uniqueProvider.type = type;
            selectedProviders.push(uniqueProvider);
            this.selectedReqProvider = uniqueProvider;
          } else {
            res.data[0].type = type;
            selectedProviders.push(res.data[0]);
            this.selectedReqProvider = res.data[0];
          }
          this.selectedProvList.next(selectedProviders);
        }
      });
    }
    if (authForm.authIntakeHeader.srvcFinalProviderHashId) {
      this.getProviderByHashId(authForm.authIntakeHeader.srvcFinalProviderHashId, authForm.authIntakeDetail[0].authDetailId, PROVIDER_TYPE.SERVICING).subscribe((res) => {
        if (res.succeeded && res.data) { 
          let type = this.lookupDataSet.value.filter(t => t.lookupValueID == 10042)[0]?.lookupStandardLabel;
          res.data.type = type;
          this.SvcProviderTemp.next(res.data.isTempProvider);
          selectedProviders.push(res.data);
          this.selectedSvcProvider = res.data;
          if (res.data.providerInfoChanged){
            authForm.authIntakeHeader.srvcFinalProviderHashId = res.data.hashId;
            Swal.fire({
              title: 'Servicing Provider Updated',
              text: `Servicing Provider Information has been updated. Please verify the correct provider is selected.`,
              icon: 'info', //possible values - error/success/info/question
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: '#5890a3',
              confirmButtonText: 'OK'
            });
          }
          this.selectedProvList.next(selectedProviders);
        } else if (res.succeeded && !res.data) { //The provider info has updated and the hashId no longer exists. We cannot retrieve provider info reliably. Display message for user to select provider again
          Swal.fire({
            title: 'Provider Not Found',
            text: `Servicing provider not found. Please search for provider again using NPI ${authForm.authIntakeHeader.srvcNPI}`,
            icon: 'error', //possible values - error/success/info/question
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#5890a3',
            confirmButtonText: 'OK'
          });
        }
      });
    }
    else if (authForm.authIntakeHeader.srvcNPI && authForm.authIntakeHeader.srvcNPI) {
      this.getProviderByProviderInfo(authForm.authIntakeHeader.srvcNPI, authForm.authIntakeHeader.srvcServiceLocationID,authForm.authIntakeDetail[0].authDetailId).subscribe((res) => {
        if (res.succeeded && res.data && res.data.length > 0) { 
          let type = this.lookupDataSet.value.filter(t => t.lookupValueID == 10042)[0]?.lookupStandardLabel;
          let uniqueProvider = res.data.find(p => p.hashId == authForm.authIntakeHeader.srvcFinalProviderHashId);
          if (uniqueProvider) {
            uniqueProvider.type = type;
            selectedProviders.push(uniqueProvider);
            this.selectedSvcProvider = uniqueProvider;
          } else {
            res.data[0].type = type;
            selectedProviders.push(res.data[0]);
            this.selectedSvcProvider = res.data[0];
          }
          this.selectedProvList.next(selectedProviders);

        }
      });
    }
    if (authForm.authIntakeOtherProviders && authForm.authIntakeOtherProviders.length > 0) {
      authForm.authIntakeOtherProviders.forEach(p => {
        if (p.finalProviderHashId && p.obsolete === false) {
          this.getProviderByHashId(p.finalProviderHashId,authForm.authIntakeDetail[0].authDetailId,PROVIDER_TYPE.OTHER).subscribe((res) => {
            if (res.succeeded && res.data) {
              let type = this.lookupDataSet.value.filter(t => t.lookupValueID == p.providerTypeId)[0]?.lookupStandardLabel;

              res.data.type = type;
              selectedProviders.push(res.data);
            }
            this.selectedProvList.next(selectedProviders);

          });
        }
      })
    }
    else if (authForm.authIntakeOtherProviders !== undefined && authForm.authIntakeOtherProviders.length > 0) {
      authForm.authIntakeOtherProviders.forEach(p => {
        if (p.npi && p.obsolete === false) {
          this.getProviderByProviderInfo(p.npi, p.serviceLocationID,p.authDetailId).subscribe((res) => {
            if (res.succeeded && res.data && res.data.length > 0) {
              let type = this.lookupDataSet.value.filter(t => t.lookupValueID == p.providerTypeId)[0]?.lookupStandardLabel;
              let uniqueProvider = res.data.find(r => r.hashId == p.finalProviderHashId);
              if (uniqueProvider) {
                uniqueProvider.type = type;
                selectedProviders.push(uniqueProvider);
              } else {
                res.data[0].type = type;
                selectedProviders.push(res.data[0]);
              }
                  this.selectedProvList.next(selectedProviders);
            }
          });
        }
      })
    }
    this.selectedProvList.next(selectedProviders);
  }

  getProviderByProviderInfo(npi: string | null, serviceLocationID: string | null, authDetailId: number | null) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetProvidersByProviderInfo";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("npi", npi??'');
    queryParams = queryParams.append("serviceLocationID", serviceLocationID??'');
    queryParams = queryParams.append("authDetailId", authDetailId??'');

    return this.http.get<ApiResponse<Provider[]>>(lookupUrl, { params: queryParams });
  }

  getProviderByHashId(hashId: string, authDetailId: number, providerType: PROVIDER_TYPE) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetProviderByHashID";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("hashID", hashId);
    queryParams = queryParams.append("authDetailId", authDetailId??0);
    queryParams = queryParams.append("providerType", providerType);

    return this.http.get<ApiResponse<Provider>>(lookupUrl, { params: queryParams });
  }

  validateProviderNPI(npi: string) {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/ValidateProviderNPI";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("npi", npi);

    return this.http.get<ApiResponse<string>>(lookupUrl, { params: queryParams });
  }

  getLookupValuesByLookupSetName(lookupSets: string[]):Observable<LookupValue[]|void> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let queryParams = new HttpParams();
    queryParams = queryParams.append("lookupSets", lookupSets.join(','));
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("productId", 5);
    let lookupUrl = this.lookupServiceBaseUrl + "LookupValue/GetLookupValuesByLookupSetName";
    let options = { headers: headers, params: queryParams };

    return this.http.get<ApiResponse<LookupValue[]>>(lookupUrl, options).pipe(
        map((data)=>{
          console.log("getLookupValuesByLookupSetName");
          if (data && data.succeeded) {
            this.udfLookupValues.next(data.data!);
          }
          return data.data;
        })
    );
  }

  getProcedureCodes(): void {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetProcedures";
    this.http.get<ApiResponse<Procedure[]>>(lookupUrl).subscribe(
      data => {
        if (data.succeeded == true) {
          this.procList.next(data.data!);
          console.log("GetProcedures");
        }
      }
    );
  }

  getDiagnosisCodes(): void {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupClientFeed/GetDiagnosis";
    this.http.get<ApiResponse<Diagnosis[]>>(lookupUrl).subscribe(
      data => {
        if (data.succeeded == true) {
          this.diagList.next(data.data!);
        }
      }
    );
  }

  getAllLookupValues(forceRefresh:boolean=false) {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });

    let queryParams = new HttpParams();
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("productId", 5);
    queryParams = queryParams.append("forceRefresh", forceRefresh);
    let lookupUrl = this.lookupServiceBaseUrl + "LookupValue/GetActiveLookupValuesByCustomerProduct";
    let options = { headers: headers, params: queryParams };
    this.http.get<ApiResponse<LookupValue[]>>(lookupUrl, options).subscribe(l => {
      if (l && l.succeeded && l.data) {
        this.baseLookupSet = l.data;
        this.lookupDataSet.next(l.data!);
        this.TaskSuccessIds.next(l.data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_SUCCESSFUL.toLowerCase()).map(o => o.lookupValueID));
        this.TaskUnsuccessfulIds.next(l.data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_UNSUCCESSFUL.toLowerCase()).map(o => o.lookupValueID));
        this.TaskPendingIds.next(l.data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_PENDING.toLowerCase()).map(o => o.lookupValueID));
        this.RequestorTypes.next(l.data.filter(lookup => lookup.lookupSetID == 114 && lookup.isActive));
        this.RequestTypes.next(l.data.filter(lookup => lookup.lookupSetID == 112 && lookup.isActive));
        this.UrgencyTypes.next(l.data.filter(lookup => lookup.lookupSetID == 109 && lookup.isActive));
        this.TreatmentTypes.next(l.data.filter(lookup => lookup.lookupSetID == 110 && lookup.isActive));
        this.TATUnitTypes.next(l.data.filter(lookup => lookup.lookupSetID == 151 && lookup.isActive));
      }
    });
    this.configService.lookupValueConfig.subscribe(c => {
      if (c && c.length > 0) {
        const lookupGuids = new Set(c.map(({ lookupValueGUID }) => lookupValueGUID));
        const refData = [...this.baseLookupSet];
        const data = Array.from(new Set(refData.filter(({ lookupValue_GUID }) => lookupGuids.has(lookupValue_GUID))));
        let allData = Array.from(new Set(refData));
        data.forEach(f => {
          allData = allData.filter(a => {
            return a.lookupSetID !== f.lookupSetID;
          });
        })
        const finalData = [...allData, ...data];
        this.lookupDataSet.next(finalData);
        this.TaskSuccessIds.next(finalData.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_SUCCESSFUL.toLowerCase()).map(o => o.lookupValueID));
        this.TaskUnsuccessfulIds.next(finalData.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_UNSUCCESSFUL.toLowerCase()).map(o => o.lookupValueID));
        this.TaskPendingIds.next(finalData.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_PENDING.toLowerCase()).map(o => o.lookupValueID));
      }
    })
  }

  getUSStates(): void {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupValue/GetUSStates";
    this.http.get<ApiResponse<[]>>(lookupUrl).subscribe(
      data => {
        if (data.succeeded == true) {
          this.stateList.next(data.data!);
        }
      }
    );
  }

  getTATStatusList(): void {
    let lookupUrl = this.lookupServiceBaseUrl + "LookupValue/GetTATStatus";
    this.http.get<ApiResponse<DueDateRuleChangeStatus[]>>(lookupUrl).subscribe(
      data => {
        if (data.succeeded == true) {
          this.TATStatusList.next(data.data!);
        }
      }
    );
  }

  getLookupSets():Observable<LookupSet[]|void> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    
    let lookupUrl = this.lookupServiceBaseUrl + "LookupSet/GetLookupSets";
    let options = { headers: headers };

    return this.http.get<ApiResponse<LookupSet[]>>(lookupUrl, options).pipe(
        map((data)=>{
          console.log("GetLookupSets");
          if (data.data){
            this.LookupSet = data.data;
          }
          return data.data;
        })
    );
  }

  getAllLookups():Observable<LookupValue[]|void> {
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    
    let lookupUrl = this.lookupServiceBaseUrl + "LookupValue/GetLookupValues";
    let options = { headers: headers };

    return this.http.get<ApiResponse<LookupValue[]>>(lookupUrl, options).pipe(
        map((data)=>{
          if (data.data){
            this.AllLookupValues = data.data;
          }
          return data.data;
        })
    );
  }
}
