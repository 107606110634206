import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config-service.service';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { AppSettings } from '../../shared/appsettings';
import { FormService } from '../../services/form-service.service';
import { AGService } from '../../services/ag.service';
import { InterqualComponent } from '../../shared/components/interqual/interqual.component';
import { SignalrService } from '../../services/signalr.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthIntakeRecentEvent } from '../../models/authView/authIntakeRecentEvent';
import { TokenStorageService } from '../../services/token-storage.service';
import { EDIService } from '../../services/edi.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CustomLinks } from '../../models/configuration/customLinks';
import { SelfServeService } from '../../services/self-serve.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('impersonateDialogTemplate') impersonateRoleTemplate: TemplateRef<any> | undefined;
  @ViewChild('unlockAuthDialogTemplate') unlockAuthTemplate: TemplateRef<any> | undefined;
  @ViewChild('resubmitAuthDialogTemplate') resubmitAuthTemplate: TemplateRef<any> | undefined;
  @Output() public sidenavToggle = new EventEmitter();
  impersonateRoleId!: number;
  sub!: Subscription;
  proceedSub!: Subscription;
  configSub!: Subscription;
  recentEventsSub!: Subscription;
  reportingMenu!: boolean;
  faxArchiveMenu!: boolean;
  lockedAuthNum: string = '';
  invalidAuthNumber = false;
  validationMsg!: string;
  configurations!: boolean;
  superUser = true;
  extId!: string;
  reviewType!: number;
  recentEventsClosed!: AuthIntakeRecentEvent[];
  recentEventsViewed!: AuthIntakeRecentEvent[];
  user!: any;
  userName!: string;
  customerId!: number;
  resubmitAuthNum: string = '';
  resubmitMemberId: string = '';
  invalidMemberId = false;
  overrideEdit = false;
  overrideSSC = false;
  memberIdValidationMsg = '';
  validationError = '';
  customLinks: CustomLinks[] = [];
  appealUrl = '';
  appealSAML = '';
  externalGuidelines = false;
  
  constructor(private authService: AuthService, private router: Router, private configService: ConfigService, private tokenService: TokenStorageService, private dialog: MatDialog,
    private appSettings: AppSettings, private formsService: FormService, private agService: AGService, private signalR: SignalrService, private ediService: EDIService,
    private selfServe: SelfServeService) {
    if (appSettings.features && appSettings.features['reportingMenu']) {
      this.reportingMenu = JSON.parse(appSettings.features['reportingMenu']);
      this.faxArchiveMenu = JSON.parse(appSettings.features['faxArchiveMenu']);
    }
    if (appSettings.features && appSettings.features['configurations']) {
      this.configurations = JSON.parse(appSettings.features['configurations']);
    }
    if (this.appSettings.features && this.appSettings.features['externalGuidelines']) {
      this.externalGuidelines = JSON.parse(this.appSettings.features['externalGuidelines']);
    }
  }

  ngOnInit(): void {   
    this.customerId = this.appSettings.customerId;
    this.lockedAuthNum = '';
    this.resubmitAuthNum = '';
    this.resubmitMemberId = '';
    this.validationError = '';
    this.validationMsg = '';
    this.memberIdValidationMsg = '';
    this.invalidAuthNumber = false;

    this.recentEventsSub = this.formsService.RecentEventList.subscribe(e => {
      if (e.length > 0) {
        this.recentEventsClosed = e.filter(f => f.eventType == 'Closed');
        this.recentEventsViewed = e.filter(f => f.eventType == 'Viewed');
      }
    });
    
    this.authService.loginComplete.subscribe(e => {
      let user = this.tokenService.getUser();
      if (e || user.profile ) {
          this.formsService.getRecentEvents(user.profile.preferred_username);
      }
    });

    this.sub = this.agService.LoginUrl.subscribe(data => {
      if (data) {
        this.appealUrl = data.url;
        this.appealSAML = data.saml64;
        this.launchAG();
      }
  });

  this.selfServe.customLinks.subscribe(l => {
      if (l && l.length > 0){
        this.customLinks = l.filter(cl => !cl.obsolete);
        this.customLinks = this.customLinks.sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1))
      }
    })
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  logout() {
    this.authService.logout();
  }

  openImpersonateRoleDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      height: 'auto',
      width: '25em',
      data: <IDialogConfig>{
        title: 'Impersonate Role',
        dialogContent: this.impersonateRoleTemplate,
        acceptButtonTitle1: 'Proceed',
        declineButtonTitle: 'Cancel'
      }
    });

    this.proceedSub = dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
      this.configService.impersonateUser(this.impersonateRoleId);
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      // delete it
    });
  }

  openUnlockAuthDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      height: 'auto',
      width: '25em',
      data: <IDialogConfig>{
        title: 'Unlock Auth',
        dialogContent: this.unlockAuthTemplate,
        acceptButtonTitle1: 'Unlock',
        declineButtonTitle: 'Cancel'
      }
    });

    this.proceedSub = dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
      if (this.validAuthNumber(this.lockedAuthNum)) {
        this.invalidAuthNumber = false;
        this.formsService.unlockFormByAuth(+this.lockedAuthNum.split('-')[1], this.lockedAuthNum.split('-')[0]);
        dialogRef.close();
      } else {
        this.invalidAuthNumber = true;
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      // delete it
    });
  }

  openAuthResubmission(){
    const dialogRef = this.dialog.open(DialogComponent, {
      height: 'auto',
      width: '25em',
      data: <IDialogConfig>{
        title: 'Resubmit Authorization',
        dialogContent: this.resubmitAuthTemplate,
        acceptButtonTitle1: 'Resubmit',
        declineButtonTitle: 'Cancel'
      }
    });
    this.lockedAuthNum = '';
    this.resubmitAuthNum = '';
    this.resubmitMemberId = '';
    this.validationError = '';
    this.validationMsg = '';
    this.memberIdValidationMsg = '';
    this.invalidAuthNumber = false;
    this.invalidMemberId = false;

    this.proceedSub = dialogRef.componentInstance.clickAcceptButton1.subscribe(() => {
      if (this.validAuthNumber(this.resubmitAuthNum) && this.validMemberId()) {
        this.invalidAuthNumber = false;
        let authNum = this.resubmitAuthNum.split('-');

        this.formsService.validateResubmitAuth(this.resubmitMemberId, authNum[0],authNum[1]).subscribe(r => {
          if (!r) {
            this.validationError = 'Invalid Member Id/Authorization Number entered.';
          } else {
            this.validationError = '';
            this.ediService.callEdi278(this.resubmitMemberId, this.resubmitAuthNum, this.overrideEdit, this.overrideSSC).subscribe(r => {
              if (r){
                this.validationError = r;
              }
            })
            this.formsService.logResubmission(this.resubmitAuthNum.split('-')[0],this.resubmitAuthNum.split('-')[1],this.overrideEdit, this.overrideSSC);
            this.resubmitAuthNum = '';
            this.resubmitMemberId = '';
            this.validationError = '';
            this.validationMsg = '';
          }
        });  
      } else {
        if (this.validationMsg){
          this.invalidAuthNumber = true;
        }
      }
    });

    dialogRef.componentInstance.clickDeclineButton.subscribe(() => { dialogRef.close(); });
  }

  validMemberId(): boolean {
    if (this.resubmitMemberId){
      return true;
    } else {
      this.invalidMemberId = true;
      this.memberIdValidationMsg = "Member ID is required.";
      return false;
    }
  }

  overrideChanged(event: MatCheckboxChange, overrideControl: string){
    if (event.checked) {
      if (overrideControl == 'ssc'){
        this.overrideSSC = true;
      } else {
        this.overrideEdit = true;
      }
    } else {
      if (overrideControl == 'ssc'){
        this.overrideSSC = false;
      } else {
        this.overrideEdit = false;
      }
    }
  }

  openPortal(externalId: string | null) {
    this.dialog.open(InterqualComponent, {
      "width": '6000px',
      "height": '6000px',
      "maxHeight": '90vh',
      "autoFocus": true,
      "disableClose": true,
      "data": { externalId: externalId, reviewType: this.reviewType, bookView: true }
    })
  }

  appealDecision() {
    this.agService.loginAG();
  }

  launchAG() {
    if (this.appealUrl.length > 0 && this.appealSAML.length > 0) {

      let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
      var appealForm = document.createElement("form");
      appealForm.setAttribute("method", "post");
      appealForm.setAttribute("action", this.appealUrl);

      appealForm.setAttribute("target", windowName);

      var hiddenSAMLField = document.createElement("input"); 
      hiddenSAMLField.setAttribute("type", "hidden");
      hiddenSAMLField.setAttribute("name", "SAMLResponse");
      hiddenSAMLField.setAttribute("value", this.appealSAML);
      appealForm.appendChild(hiddenSAMLField);
      document.body.appendChild(appealForm);

      window.open('','_blank', windowName);
      appealForm.submit();
    }
  }

  validAuthNumber(authNumber: string) {
    if (!authNumber.includes('-')) {
      this.validationMsg = 'Invalid Auth number. Value must be in format: 0001-123456.';
      return false;
    }
    var validNum = false;
    var authPrefix = authNumber.split('-')[0];

    if (this.numericCheck(authPrefix) && authPrefix.length == 4) {
      validNum =  true;
    } else {
      this.validationMsg = 'Invalid Auth prefix. Value must be in format: 0001';
      return false;
    }

    var authNum = authNumber.split('-')[1];
    if (this.numericCheck(authNum) && authNum.length == 7) {
      validNum = true;
    }  else if (authNum.length !== 7){
      this.validationMsg = 'Invalid Auth number. Value must be in format: 1234567';
      return false;
    } else {
      this.validationMsg = 'Invalid Auth number. Value must be in format: 123456';
      return false;
    }
    return validNum;
  }

  numericCheck(value: any) {
    if (/[0-9]/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  openConfigurations(){
    this.router.navigate([`/configurations`], { skipLocationChange: true });
  }

  sendMessage() {
    this.signalR.clientMessage('Test message','admin');
    //this.signalR.broadcastMessage('Test admin message');
  }

  ngOnDestroy() {
    this.proceedSub?.unsubscribe();
    this.configSub?.unsubscribe();
    this.recentEventsSub?.unsubscribe();
  }

  redirect(action: string, par1:string, par2: string) {
    switch(action) {
      case 'member':
        this.router.navigate([`/member/authorizations/${par1}`], { skipLocationChange: true });
        break;
      case 'authorization':
        this.router.navigate([`/member/authorization-intake/${par1}/${par2}`], { skipLocationChange: true });
        break;
    }
  }

}

interface RecentlyData {
  name: string;
  memberId: string;
  authNum: string;
}
